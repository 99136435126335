import { Routes, Route, useNavigationType, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import ErrolPage from './pages/enroll/EnrollPage';
import Backoffice from 'pages/backoffice/Backoffice';
import { Policies } from 'pages/policies/Policies';
import { Terms } from 'pages/terms/terms';
import { ForgotPasswordPage } from 'pages/fogot-password/ForgotPassword'; //TODO Esta mal escrito fogot password....
import { BackofficeTermsPolicies } from 'pages/backoffice-terms-policies/BackofficeTermsPolicies';
import GrapesJSEditor from 'pages/editor-backoffice/EditoBackoffice';
import PageViewer from 'pages/iframe-landing/iframeLanding';
import PageViewer135K from 'pages/iframe-landing-135k/iframeLanding';
import PageViewerSupport from 'pages/iframe-landing-support/iframeLanding-support';
import PageViewerPrivacy from 'pages/iframe-landing-privacy/iframeLanding-privacy';
import PageViewerTerminos from 'pages/iframe-landing-terminos/iframeLanding-terminos';

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== 'POP') {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = '';
    let metaDescription = '';

    switch (pathname) {
      case '/':
        title = '';
        metaDescription = '';
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path='/' element={<PageViewer pageId='1' />} />
      <Route path='/135k' element={<PageViewer135K pageId='9' />} />
      <Route path='/support' element={<PageViewerSupport pageId='12' />} />
      <Route path='/privacy-policy' element={<PageViewerPrivacy pageId='8' />} />
      <Route path='/terms' element={<PageViewerTerminos pageId='13' />} />
      <Route path='/enroll' element={<ErrolPage />} />
      <Route path='/backoffice' element={<Backoffice />} />
      <Route path='/backoffice-editor' element={<GrapesJSEditor />} />
      <Route path='/privacy-policy' element={<BackofficeTermsPolicies />} />
      <Route path='/policies' element={<Policies />} />
      <Route path='/terms' element={<Terms />} />
      <Route path='/reset_password' element={<ForgotPasswordPage />} />
    </Routes>
  );
}
export default App;
