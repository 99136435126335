import { newServer } from 'constants/constants';
import React, { useEffect, useState } from 'react';

interface PageViewerProps {
  pageId: string;
}

const PageViewerSupport: React.FC<PageViewerProps> = ({ pageId }) => {
  const [src, setSrc] = useState(''); // Almacena la URL de la página
  const [error, setError] = useState(''); // Almacena el mensaje de error, si ocurre

  //useEffect para cargar la pagina al entrar a la ruta
  useEffect(() => {
    loadPage();
  }, []);
  // Función para cargar la página
  const loadPage = async () => {
    //ruta con params id

    const pageUrl = `${newServer}landing-page/get/page-12`; // URL para obtener la página desde el backend

    try {
      const response = await fetch(pageUrl);

      if (response.ok) {
        setSrc(pageUrl); // Si la respuesta es exitosa, se establece el src del iframe
        setError(''); // Limpia cualquier error previo
      } else {
        throw new Error('Page not found');
      }
    } catch (error) {
      setError('Error: Page not found'); // Muestra un mensaje de error si no se encuentra la página
      setSrc(''); // Limpia el iframe si hay un error
    }
  };

  return (
    <div>
      {/* Mostrar mensaje de error si ocurre */}
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {/* Mostrar iframe si el src está definido */}
      {src && (
        <iframe
          src={src}
          title={`Page ${pageId}`}
          style={{ width: '100%', height: '100vh', border: 'none', backgroundColor: 'white' }}
        />
      )}
    </div>
  );
};

export default PageViewerSupport;
